import s from "./Galery.module.css";
import artidance from "../images/artidance.jpg";
import esigns from "../images/esigns.jpg";
import energy from "../images/energy.jpg";
import {Link } from "react-router-dom";

const Media = () =>(
    <div className={s.scroll_container}>
    <p className={s.header}>Videomapping for Performances</p>
    <div className={s.parent}>
        <div className= {s.column2}>
            <div className={`${s.card} ${s.group1}`}>
            <Link to="/subpage/artidance"><img src={artidance}  alt="artidance" /></Link>
            <p className={s.title}>Artificial Dance</p>
            <p className={s.subtitle}>An Interactive Performance</p>
            </div>
        </div> 
        <div className= {s.column2}>
            <div className={`${s.card} ${s.group2}`}>
            <Link to="/subpage/esigns"><img src={esigns} alt="esigns" /></Link>
            <p className={s.title}>Embodied Signs</p>
            <p className={s.subtitle}>Dance, Interactive Music and Videomapping</p>
            </div>
        </div>         
    </div>
    <p className={s.header}>Physical Computing</p>
    <div className={s.parent}>
        <div className= {s.column}>
            <div className={`${s.card} ${s.group1}`}>
            <Link to="/subpage/energy"><img src={energy}  alt="energy" /></Link>
            <p className={s.title}>Wind Energy</p>
            <p className={s.subtitle}>Visualizing Sustainable Energy Sources</p>
            </div>
        </div>        
    </div>
    </div>
)

export default Media;