import s from "./Galery.module.css";
import datar  from "../images/datar.jpg";
import personendepot from "../images/personendepot.jpg";
import ulkus from "../images/ulkus.jpg";
import uxa from "../images/uxa.jpg";
import {Link } from "react-router-dom";

const Galery = () =>(
    <div className={`${s.parent} ${s.scroll_container}`}>
        <div className= {s.column}>
            <div className={`${s.card} ${s.group1}`}>
            <Link to="/subpage/datar"><img src={datar}  alt="datar" /></Link>
            <p className={s.title}>datAR</p>
            <p className={s.subtitle}>Teaching Data Literacy Through Everyday Objects</p>
            </div>
            <div className={`${s.card} ${s.group3}`}>
            <Link to="/subpage/ulkus"><img src={ulkus} alt="ulkus" /></Link>
            <p className={s.title}>Ulkus</p>
            <p className={s.subtitle}>A Ritual of Collective Embodied Release</p>
            </div>
        </div> 
        <div className= {s.column}>
            <div className={`${s.card} ${s.group2}`}>
            <Link to="/subpage/personendepot"><img src={personendepot} alt="personendepot" /></Link>
            <p className={s.title}>Personendepot</p>
            <p className={s.subtitle}>A Social Anti-social Media Platform</p>
            </div>
            <div className={`${s.card} ${s.group4}`}>
            <Link to="/subpage/uxa"><img src={uxa} alt="UXA" /></Link>
            <p className={s.title}>UXA</p>
            <p className={s.subtitle}>User Experience Awareness/Art</p>
            </div>
        </div>         
    </div>
)

export default Galery;