import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import SubPage from './SubPage/SubPage';
import { useState } from 'react';

function App() {
  const [navigation, setNavigation] = useState("main");
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home data ={navigation}/>} />
        <Route path="/subpage/:id" element={<SubPage setNavigation={setNavigation}/>} /> 
      </Routes>
    </Router>
  );
}

export default App;
