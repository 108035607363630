import s from "./BackGround.module.css";
const BackGround = ({page}) => {
    let path;
    if(page === "other" ){
        path = ( 
        <div>
        <svg  width="100%" height="120vh"  fill="none" xmlns="http://www.w3.org/2000/svg" className={s.bg}>
        <path className={s.path} d="M24 -113C46 10.5 130.1 416.4 396.5 472C729.5 541.5 1081.73 674.523 953.5 826.5C818.5 986.5 831 1745 1553 1937.5" stroke="#00D1E7" stroke-width="140"/>
        </svg>
        </div>);
    } else {
        path = (
        <div>
        
        <svg  width="100%" height="100vh"  fill="none" xmlns="http://www.w3.org/2000/svg" className={s.bg}>
        <path className={s.path} d="M24 -113C46 10.5 130.1 416.4 396.5 472C729.5 541.5 1081.73 674.523 953.5 826.5C818.5 986.5 831 1045 1953 1237.5" stroke="#00D1E7" stroke-width="140"/>
        </svg>
        </div>);
    }
    console.log("bg " + page);
    return path
};

export default BackGround;