import s from "./NavBar.module.css";
import React, { useState } from 'react';

const NavBar = ({sendData, navigation}) =>{
    const [activeItem, setActiveItem] = useState(navigation);

    // Handler to set active item and send data
    const handleClick = (item) => {
      setActiveItem(item);
      sendData(item);
    };
  
    
    return(
    <div className={s.navbar}>
        <div className={s.mains}>
        <div className={s.name}>Lilian Lopez</div>
        <div onClick={() => handleClick("main")} className={`${s.textwrapper} ${activeItem === "main" ? s.active : ""}`}>Main Projects</div>
        <div onClick={() => handleClick("media")} className={`${s.textwrapper} ${activeItem === "media" ? s.active : ""}`}>Interactive Media</div>
        <div className={s.textwrapper}>Animation</div>
        <div className={s.textwrapper}>About</div>
    </div>
    </div>
)
};
export default NavBar;